.tag-table-section {
    .tag-table-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .to_assign_tag {
        display: flex;
        justify-content: center;
        border-radius: 20px;
        width: 95%;

        .tag-table {
            text-decoration: none;
        }

        &:hover {
            background-color: rgb(0, 178, 233) !important;
        }
    }

    .tag-table {
        width: 66%;
        padding: 4px 30px;
        border-radius: 20px;
        color: white;
        letter-spacing: 1px;
        font-size: 12px;
        line-height: 13px;
        display: flex;
        justify-content: center;
        text-decoration: none;
        transition: box-shadow 0.1s ease-in;

        &.provider-choice {
            width: 60% !important;
        }
    }
}