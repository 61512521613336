@import "react-redux-toastr/src/styles/index";

.App {
    text-align: center;
}

.divider {
    border-bottom: 1px solid black;
    padding: 10px 0px;
}

th {
    text-align: left;
}

.react-tel-input {
    margin-top: 11px;
}

.form-react {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.field-form {
    display: inline-flex;
    flex-direction: column;

    input[type="button"],
    input[type="submit"],
    input[type="reset"] {
        background-color: #A1E89B;
        border: none;
        color: white;
        padding: 16px 32px;
        text-decoration: none;
        margin: 4px 2px;
        opacity: 0.7;
        cursor: pointer;
        text-transform: uppercase;
    }
}

.preview_image {
    display: inline-flex;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50px;
        border: 1px solid #ccc;
        object-fit: contain;
    }
}

.flex_row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .small_box {
        width: 40%;
    }

    .big_box {
        width: 60%;
    }
}

.flex_center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex_column {
    display: flex;
    flex-direction: column;
}

.submit_icon {
    border: 0;
    background-color: transparent;
}

.form_row {
    display: flex;
    flex-direction: row;
}

.align_items_center {
    align-items: center;
}

.hidden {
    display: none;
}

.pointer {
    cursor: pointer;
}

.actions svg {
    cursor: pointer;
}

.mat_select {
    width: 200px;
}

.to-upper {

    text-transform: uppercase;
}