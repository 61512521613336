.infos_client_popup {
    width: fit-content;
    height: fit-content;
    background-color: white;
    z-index: 1;
    position: absolute;
    padding: 10px 45px 12px 12px;
    box-shadow: 4px 5px 13px rgba(71, 69, 69, 0.473);
    gap: 20px;
    display: grid;


    .grid-4 {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-auto-rows: 20px;
        border: 1px solid black;
        border-top: none;
        box-sizing: content-box;
        align-items: center;

        &.title {
            background-color: black;
            color: white;
            font-weight: 600;
        }
    }
}

.no_infos_client_popup {
    background-color: white;
    padding: 10px;
    position: absolute;
    box-shadow: 4px 5px 13px rgba(71, 69, 69, 0.473);
}